<!--
* @description:
* @fileName pageEdit.vue
* @author hvv
* @date 2022/01/18 09:36:48
!-->
<template>
  <el-drawer
    ref="drawerRef"
    v-model="dialogFormVisible"
    :title="title"
    :size="size"
    direction="rtl"
    custom-class="demo-drawer"
  >
    <el-form ref="formRef" label-width="80px" :model="form" :rules="rules">
      <el-form-item label="审核状态" prop="status">
        <el-select v-model="form.status">
          <el-option label="通过" :value="'PUBLISHED'"></el-option>
          <el-option label="驳回" :value="'NO_PASS'"></el-option>
          <!-- <el-option label="待审核" :value="'UNDER_REVIEW'"></el-option> -->
        </el-select>
      </el-form-item>

      <el-form-item label="列表名称" prop="listName">
        <el-input v-model="form.listName" />
      </el-form-item>

      <el-form-item label="列表地址" prop="listAddress">
        <el-input v-model="form.listAddress" />
      </el-form-item>

      <el-form-item label="列表时间" prop="listTime">
        <el-input v-model="form.listTime" />
      </el-form-item>

      <!-- <el-form-item label="页面关联" prop="pageType">
        <el-radio-group v-model="form.pageType">
          <el-radio :label="'MP_BUILD'">小程序搭建（默认勿动）</el-radio>
          <el-radio :label="'CMS'">后台CMS搭建</el-radio>
        </el-radio-group>
      </el-form-item> 

      <el-form-item label="页面模版" prop="pageId" v-if="form.pageType == 'CMS'">
        <el-select v-model="form.pageId">
          <template v-for="(item, index) in pageList" :key="index">
            <el-option :label="item.name" :value="item.id"></el-option>
          </template>
        </el-select>
      </el-form-item> -->

      <!-- <el-form-item
        label="闸机供应商"
        prop="gateProvider"
      >
        <el-select v-model="form.gateProvider">
          <template v-for="(item, index) in providerList" :key="index">
            <el-option :label="item.label" :value="item.value"></el-option>
          </template>
        </el-select>
      </el-form-item> -->

      <el-form-item label="列表封面" prop="listCover" :label-width="90">
        <el-upload
          class="upload-demo"
          action="#"
          list-type="picture-card"
          :limit="1"
          :file-list="form.imgList"
          :http-request="upLogo"
          :on-error="handleError"
          :on-exceed="handleExceed1"
          :on-preview="handlePreview"
          :on-remove="handleRemove1"
          accept=".png,.jpeg,.jpg"
        >
          <el-icon class="el-icon--upload"><upload-filled /></el-icon>
          <div class="el-upload__text">
            <em>点击上传图片</em>
          </div>
          <!-- <template #tip>
              <div class="el-upload__tip">
                jpg/png files with a size less than 500kb
              </div>
            </template> -->
        </el-upload>

        <!-- <el-image
            :src="form.bannerUrl"
            :fit="'fill'"
            v-else
            style="width: 360px; height: 180px"
          ></el-image> -->
      </el-form-item>
    </el-form>
    <div class="demo-drawer__footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="save()">提交</el-button>
    </div>
  </el-drawer>

  <el-dialog v-model="dialogVisible" append-to-body title="查看大图">
    <div>
      <el-image :src="dialogImageUrl" />
    </div>
  </el-dialog>
</template>

<script>
  import {
    defineComponent,
    getCurrentInstance,
    onMounted,
    reactive,
    onActivated,
    toRefs,
  } from 'vue'
  import { upLoadImg } from '@/api/active'
  import {
    saveEventPage,
    editEventPage,
    getPage,
    getEventType,
  } from '@/api/page'

  import { editExhibitionStatus } from '@/api/hostApply'

  export default defineComponent({
    name: 'pageEdit',
    emits: ['fetch-data'],
    setup(props, { emit }) {
      const { proxy } = getCurrentInstance()

      const state = reactive({
        formRef: null,
        treeRef: null,
        size: '30%',
        checkMenu: [],
        pageList: [],
        eventTypeList: [],
        providerList: [
          {
            label: '澳龙',
            value: 'INFO_SALONS',
          },
          {
            label: '租达人',
            value: 'ZDR',
          },
          {
            label: 'WeMeet',
            value: 'WEMEET',
          },
        ],
        form: {
          pageData: '',
          pageType: 'CMS',
          imgList: [],
          pageId: '',
          gateProvider: 'WEMEET',
        },
        queryForm: { name: '', pageNumber: 1, pageSize: 99999 },
        rules: {
          status: [
            { required: true, trigger: 'blur', message: '请选择审核状态' },
          ],
          listName: [
            { required: true, trigger: 'blur', message: '请输入列表名称' },
          ],
          listCover: [
            { required: true, trigger: 'change', message: '请选择事件图片' },
          ],
          pageId: [
            { required: true, trigger: 'change', message: '请选择事件模版' },
          ],
        },
        title: '',
        dialogFormVisible: false,
        dialogVisible: false,
        dialogImageUrl: '',
        list: [],
      })

      const showEdit = async (row) => {
        if (!row) {
          state.title = '添加'
        } else {
          state.title = '编辑'

          state.form = Object.assign({}, row)

          state.form.listName = state.form.listName
            ? state.form.listName
            : state.form.meetingName
          state.form.listAddress = state.form.listAddress
            ? state.form.listAddress
            : state.form.meetingAddress
          state.form.listTime = state.form.listTime
            ? state.form.listTime
            : state.form.meetingTime
          // 默认WeMeet
          // state.form.gateProvider = !!state.form.gateProvider ? state.form.gateProvider : 'WEMEET'
          state.form.imgList = [{ name: 'logo', url: row.listCover }]
          if (row.listCover) {
            state.form.imgList = [{ name: 'logo', url: row.listCover }]
          } else {
            state.form.imgList = []
          }
          console.log('state.form.imgList', state.form.imgList)
          state.form.pageId = state.form.pageId ? Number(state.form.pageId) : ''
        }
        state.dialogFormVisible = true
        fetchData()
      }
      const close = () => {
        state['formRef'].resetFields()
        state.form = {
          btnRolesCheckedList: [],
        }
        state.dialogFormVisible = false
      }

      const save = () => {
        state['formRef'].validate(async (valid) => {
          if (valid) {
            state.form.auditStatus = state.form.status
            state.form.meetingCode = state.form.code
            let data = await editExhibitionStatus(state.form)
            proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
            emit('fetch-data')
            close()
          }
        })
      }

      // 上传海报
      const upLogo = async (e) => {
        let upData = new FormData()
        upData.append('file', e.file)
        const { data } = await upLoadImg(upData)
        state.form.listCover = data
      }

      const handleError = (err, file) => {
        proxy.$baseMessage(
          `文件[${file.raw.name}]上传失败,文件大小为${_.round(
            file.raw.size / 1024,
            0
          )}KB`,
          'error',
          'vab-hey-message-error'
        )
      }

      const handleExceed1 = (files) => {
        proxy.$baseMessage(
          `当前限制选择1个文件，本次选择了
             ${files.length}
             个文件`,
          'error',
          'vab-hey-message-error'
        )
      }

      const handleRemove1 = (file, fileList) => {
        state.form.imgUrl = ''
      }

      const handlePreview = (file) => {
        state.dialogImageUrl = file.url
        console.log(file.url)
        state.dialogVisible = true
      }

      // 获取所有事件
      // const handleGetAllEvent = async () => {
      //   const { data } = await getAllEvent()
      //   state.eventList = data
      // }

      const fetchData = async () => {
        const { data } = await getPage(state.queryForm)
        state.pageList = data.list
        // const { data: eventTypeData } = await getEventType(state.queryForm)
        // state.eventTypeList = eventTypeData.data
      }

      // onActivated(() => {
      //   fetchData()
      // })

      return {
        ...toRefs(state),
        showEdit,
        close,
        save,
        upLogo,
        handleError,
        handleExceed1,
        handleRemove1,
        handlePreview,
      }
    },
  })
</script>

<style lang="scss" scoped>
  .vab-tree-border {
    height: 200px;
    padding: $base-padding;
    overflow-y: auto;
    border: 1px solid #dcdfe6;
    border-radius: $base-border-radius;
  }

  .demo-drawer__content {
    height: 85vh;
    overflow: auto;
  }
  .demo-drawer__footer {
    position: fixed;
    right: 20px;
    bottom: 10px;
  }
</style>
